import Header from "@layouts/Header";
import DefaultSection from "@sections/DefaultSection";
import React from "react";
import SwiperCore, { Autoplay, Pagination } from "swiper/";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "./components/card/card";
import TestimonialsSection from "./components/TestimonialsSection/TestimonialsSection";
import "./Pricing.styles.scss";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

const PricingTemplate = ({ data }) => {
  const header = data?.header || {};
  const cards = data?.cards || {};
  const process = data?.process || {};
  const requirements = data?.requirements || {};
  const testimonials =
    (data?.customers && data?.customers.items) || [];

  const [swiper, updateSwiper] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleChangeActiveKey = (key) => {
    swiper && swiper.slideTo(key);
    setActiveStep(key);
  };

  return (
    <main className="pricing">
      <Header {...header} isLong isCentered />
      <section className="pricing__card-section">
        <div className="pricing__card-wrapper">
          <Card {...cards[0]} />
          <Card {...cards[1]} isPrimary />
          <Card {...cards[2]} />
        </div>
        <Swiper
          pagination={true}
          onSlideChange={(item) => {
            handleChangeActiveKey(item.realIndex);
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          onSwiper={updateSwiper}
          className="pricing__swiper"
        >
          <SwiperSlide className="pricing__swiper-slide">
            <Card {...cards[0]} />
          </SwiperSlide>
          <SwiperSlide className="pricing__swiper-slide">
            <Card isPrimary {...cards[1]} />
          </SwiperSlide>
          <SwiperSlide className="pricing__swiper-slide">
            <Card {...cards[2]} />
          </SwiperSlide>
        </Swiper>
      </section>
      <section className="pricing__process-section">
        <h2 className="pricing__process-title">{process.title}</h2>
        <div className="pricing__process-paragraph-wrapper">
          {process.paragraphs.map((paragraph, index) => (
            <p key={index} className="pricing__process-paragraph">
              {paragraph}
            </p>
          ))}
        </div>
        <h2 className="pricing__process-title">
          {process.stepTitle}
        </h2>
        <div className="pricing__process-step-wrapper">
          {process.steps.map((step, index) => (
            <div key={index} className="pricing__process-step">
              <span className="pricing__process-step-counter">
                {++index}
              </span>
              <span className="pricing__process-step-text">
                {step}
              </span>
            </div>
          ))}
        </div>
      </section>
      <DefaultSection
        className="pricing__requirements-section"
        info={{
          ...requirements,
        }}
        image={requirements?.image}
        isReverse
      />
      <TestimonialsSection testimonials={testimonials} />
    </main>
  );
};

export default PricingTemplate;
