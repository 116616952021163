import React from "react";
import { withPrefix } from "gatsby";
import Layout from "@layouts/Layout";

import PricingTemplate from "./Pricing";

const PricingPage = ({ data: initialData }) => {
  // const data = initialData?.markdownRemark?.frontmatter;
  const data = {
    ...initialData?.markdownRemark?.frontmatter,
    ...initialData?.testimonials?.frontmatter,
  };
  const seo = {
    ...(data?.seo || {}),
    image: data?.seo?.image?.childImageSharp?.fluid?.src,
  };
  return (
    <Layout seo={seo}>
      <PricingTemplate data={data} />
    </Layout>
  );
};

export default PricingPage;

export const pricingPageQuery = graphql`
  query PricingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Pricing" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header {
          title
          description
          buttonText
          subTitle
        }
        cards {
          title
          subtitle
          cta {
            title
            link
          }
          priceTag {
            price
            subscription
            subtitle
          }
          features {
            text
            isChecked
          }
        }
        process {
          title
          paragraphs
          stepTitle
          steps
        }
        requirements {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    testimonials: markdownRemark(
      frontmatter: { templateKey: { eq: "Testimonials" } }
    ) {
      frontmatter {
        customers {
          category
          title
          description
          items {
            description
            author {
              fullname
              company
              image {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
