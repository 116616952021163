import React, { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Link } from "gatsby";
import Button from "@components/Button";
import classNames from "classnames";
import Check from "../svg/check.svg";
import "./card.styles.scss";
const Card = ({
  isPrimary,
  title,
  subtitle,
  cta,
  priceTag,
  features,
}) => {
  return (
    <>
      <div
        className={classNames("pricing__card", {
          "pricing__card--primary": isPrimary,
        })}
      >
        <h3 className="pricing__card-title">{title}</h3>
        <Link className="pricing__card-button-link" target="_blank" to={cta.link}>
          <Button type="big">{cta.title}</Button>
        </Link>
        {priceTag.price && (
          <div className="pricing__card-priceTag">
            <span className="pricing__card-price">
              {priceTag.price}
            </span>
            <span className="pricing__card-subscription">
              {priceTag.subscription}
            </span>
            <span className="pricing__card-price-subtitle">
              {priceTag.subtitle}
            </span>
          </div>
        )}

        <div>
          {features.map((item, index) => (
            <div key={index} className="pricing__card-feature-wrapper">
              <div className="pricing__card-check-wrapper">
                {item.isChecked ? <Check /> : ""}
              </div>
              <span className="pricing__card-feature">{item.text}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Card;
